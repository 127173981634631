<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <el-form-item label="ID" prop="id" v-if="dataForm.id">
        <el-input
          v-model="dataForm.id"
          :disabled="true"
          placeholder="ID"
        ></el-input>
      </el-form-item>
      <el-form-item label="打印机名称" prop="name">
        <el-input
          v-model="dataForm.name"
          :disabled="disabled"
          placeholder="打印机名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="机器码" prop="code">
        <el-input
          v-model="dataForm.code"
          :disabled="disabled"
          placeholder="机器码"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { normal } from '@/mixins';
import { keys, pick } from 'lodash';
export default {
  mixins: [normal],
  components: {},
  data() {
    return {
      disabled: false,
      visible: false,
      orgList: [],
      zoneList: [],
      dataForm: {
        id: 0,
        name: '',
        code: '',
        orgId: '',
      },
      dataRule: {
        name: [
          { required: true, message: '打印机名字不能为空', trigger: 'blur' },
        ],
        code: [{ required: true, message: '机器码不能为空', trigger: 'blur' }],
      },
    };
  },
  created() {},
  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (id) {
          this.$http({
            url: `/os/printer/info/${id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = pick(data.printer, keys(this.dataForm));
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/os/printer/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },
  },
};
</script>
